@import '@/index.less';

.table {
  :global {
    .ant-table-column-sorters{
      
      justify-content:flex-start;
      &>span{
        flex:initial
      }
    }
    .ant-table-body {
      min-height: 400px;
    }

    .ant-form-item-label {

      width: 125px;
      max-width: initial !important;
      flex: none;
      // display: block;
    }

    .ant-form-item-control {
      flex-wrap: wrap;
    }

    .ant-form-item-no-colon {
      color: @text-color-secondary !important;
      font-family: Open Sans;

    }

    .ant-select-selector {
      border-radius: initial;
      width: 220px !important;
    }

    .ant-form {
      margin-bottom: 4px;
    }


    .ant-form-item-control-input-content {
      .ant-input-affix-wrapper {

        border-radius: initial;
      }

      input {
        width: 300px !important;
      }

    }

    .ant-table-container {
      border-radius: 0;

      table {
        border-radius: 0;

      }

      .ant-table-thead {
        .ant-table-cell {
          border-radius: 0 !important;
          color: @product-table-cell-color;
          font-size: 12px;
        }
      }

      .ant-table-row {
        .ant-table-cell {
          background-color: transparent !important;
          font-size: 12px;
        }

        &:hover {
          background-color: @background-color-1;
          color: @primary-color !important;

        }
      }
    }
  }

  .selectBox {
    box-sizing: border-box;
    padding: 6px 16px;
    // height: 32px;
    border: 1px solid @border-color-1;
    transition: all 0.3s;
    cursor: pointer;
    white-space: nowrap;

    &:hover {

      .active-box();
    }
  }

  .active-box {
    color: @primary-color;
    border-color: @primary-color !important;
  }

  .row {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    &>div {
      .selectBox();
    }
  }

  .filter {
    display: flex;
    gap: 16px;

    &-img {
      width: 16px;
      position: relative;
      top: 1px;
    }
  }
  &-end-cell{
    border-bottom:0 !important;
    padding: 0 !important;
    // background-color: red;
  }
  &-end-div {
    // width: 100%;
    margin: auto;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding-top: 16px;
    color: @text-color-secondary;
    // position: absolute;
  }
}