@import '~@/index.less';

.languageDropdown {
  position: relative;
  display: flex;
  .language{
    
    color:@text-primary-hover;
      .icon{
        overflow: hidden;
        
        &>img{
          position: relative;
          // left: -16px;
          transform: translateX(-16px);
          filter: drop-shadow(16px 0px @text-primary-hover);
        }
        
      }
      .arrow{
        transform: rotate(180deg);
      }
  }
  
  .arrow{
    transition: all 0.15s ease;
  }

  .textHover:hover{
    color:@primary-color
  }

  .dropdown {
    position: absolute;
    box-sizing: border-box;
    top: 60px;
    left: 0px;
    height: 0px;
    width: 100%;
    transition: all 0.15s ease;
    background-color: @secondary-color;
    overflow: hidden;
    z-index:99999;
    &>div{
      padding:4px 20px 16px;
      width: 100%;
      height: 100%;
      display: flex;
    gap: 6px;
    }

    &-contain {
      display: flex;
      flex-direction: column;
      width: 50%;
      .content{
        padding: 7px 10px;
        word-wrap: break-word;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        font-feature-settings: 'clig' off, 'liga' off;
        text-align: left;
        &:hover{
          color:@text-primary-hover;
          cursor: pointer;
        }
        
      }
      .active{
        color:@text-primary-hover;
      }
    }
  }
}