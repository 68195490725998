// 响应式布局宽度(断点)
@screen-width-xs: 0px; //为媒体查询统一使用min-width,同时备用特殊情况
@screen-width-sm: 992px;
@screen-width-md: 1200px;
@screen-width-lg: 1440px;
// 响应式布局(可视宽度)大于对应断点时的可视宽度
@width-xs: 750px;
@width-sm: 970px;
@width-md: 1200px;
@width-lg: 1400px;

.contain-private {
  // padding: 0 20px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  // 使用断点进行适配有相对独立的媒体查询的页面或组件
  //1、tabs 2、productTable
  @media screen and(min-width:@screen-width-xs) {
    width: @width-md;
  }

  // @media screen and( min-width:@screen-width-xs) {
  //   width: @width-xs;
  // }
  // @media screen and( min-width:@screen-width-sm) {
  //   width: @width-sm;
  // }
  // @media screen and( min-width:@screen-width-md) {
  //   width: @width-md;
  // }
  @media screen and(min-width:@screen-width-lg) {
    width: @width-lg;
  }
}

:global {
  .contain {
    .contain-private();
  }
}
// :export{
//   screen-width-xs:@screen-width-xs;
//   screen-width-sm:@screen-width-sm;
//   screen-width-md:@screen-width-md;
//   screen-width-lg:@screen-width-lg;
//   width-xs:@width-xs;
//   width-sm:@width-sm;
//   width-md:@width-md;
//   width-lg:@width-lg;
// }