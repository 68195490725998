@import '@/index.less';
.benefits-content {
  display: flex;
  flex-direction: column;
  // width: @baseWidth;
  margin: auto;
  // padding: 80px 0 110px;
  padding-top: 60px;
  padding-bottom: 110px;

  &-title {
    color: @text-color;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
  }

  &-description {
    color: @text-color-secondary;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 66px;
  }

  &-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &>div {
      width: calc(50% - 10px);
      min-height: 143px;
      background-color: @primary-color;
      background-image: url('/assets/images/benefit-hover.png');
      background-size: 100%;
      background-blend-mode: darken;
      transition: all 0.5s;

      &:hover {
        transform: translateY(-10px);

        &>div {
          background-color: transparent;

          &>div {
            &>span {
              color: @text-color-dark !important;
            }

            &>div {
              color: @text-color-secondary-dark !important;
            }
          }

        }
      }

      &>div {
        width: 100%;
        height: 100%;
        padding: 35px 24px 28px 40px;
        display: flex;
        gap: 20px;
        transition: all 0.5s;
        background-color: @product-benefit-bg-color;

        &>img {
          width: 56px;
          height: 56px;

        }

        &>div {
          width: 450px;

          &>span {
            color: @text-color;
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
          }

          &>div {
            color: @text-color-secondary;
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;

            margin-top: 10px;
          }
        }
      }

    }
  }
}