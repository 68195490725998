@import '@/index.less';
@import '@/fitSize.less';
@baseWidth: 1200px;

.tabs {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  // width: @baseWidth;
  // min-width: @baseWidth;
  height: 62px;
  transition: all;
  // background: white;
  z-index: 999;
  // padding: 0 40px;
  display: flex;
  box-shadow: 0px 6px 10px 0px @box-shadow-1;
  --widthPercent: 25%;
  backdrop-filter: blur(4px);
  background-color: @tabs-bg-color;
  padding: 0 !important;

  &-content {
    margin-block-start: initial;
    padding-block-start: initial;
    border: initial;
    height: 100%;

    :global {
      .ant-anchor {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;

        &::before {
          display: none;
        }

        .ant-anchor-link {
          // flex: 1;
          width: calc(var(--widthPercent) - 35px);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 18px;
          padding: 0;

          &>a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:first-of-type {
            width: calc(var(--widthPercent) + 70px);

            &>a {
              padding-left: 70px;
            }
          }

          &:last-of-type {
            width: calc(var(--widthPercent) + 70px);

            &>a {
              padding-right: 70px;

              &>.a-content {
                position: relative;
                right: -35px;

              }
            }
          }
        }

        .ant-anchor-link-active {
          background-color: @tabs-bg-color-active;

          .ant-anchor-link-title-active {
            color: white !important;
            transition: initial;

            &>div>div>div {
              background-image: url('../../assets/svg/backlinks-hover.svg') !important;
            }
          }
        }

        .ant-anchor-link-title:hover{
          color:@text-primary-hover ;
        }

        .ant-anchor-ink {
          display: none !important;
        }
      }
    }

    &::before {
      border: none;
    }
  }

  &>div {
    width: 100%;
    height: 100%;

    &>div {
      height: 100%;
    }
  }

  .a-content-left {
    position: relative;
    left: -35px;
  }

  .a-content-right {
    position: relative;
    right: -35px;
  }
}

@media screen and(min-width:@screen-width-xs) {
  .tabs {
    min-width: @width-md - 40px;
  }

}

// @media screen and( min-width:@screen-width-xs) {
//   width: @width-xs;
// }
// @media screen and( min-width:@screen-width-sm) {
//   width: @width-sm;
// }
// @media screen and( min-width:@screen-width-md) {
//   width: @width-md;
// }
@media screen and(min-width:@screen-width-lg) {
  .tabs {
    min-width: @width-lg - 40px;
  }

}