@import '@/index.less';
@baseWidth: 1200px;

.container {
  background-color: @background-color-2;
}

.mg-auto {
  margin: auto
}

.banner {
  padding-top: 90px;
  // position: absolute;
  // width: @baseWidth;
  // left: 50%;
  // transform: translate(-50%);
  margin: auto;

  &-title {
    color: @text-color-black;
    font-family: Open Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    padding-bottom: 12px;
  }

  &-description {
    color: @text-color-secondary;
    /* H5/Regular */
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 36px;
  }

  &-btn {
    width: 140px;
    height: 48px;
    margin-right: 16px;
    font-size: 16px;
    font-weight: 600;

    // &:first-of-type {
    //   &:hover {
    //     background-color: @primary-color !important;
    //   }
    // }

    // &:not(:first-of-type) {
    //   background: transparent;
    //   border-color: @primary-color;
    //   color: @primary-color;
    // }
  }
}
:global {
  @media (any-hover:hover) {
    .ant-anchor-link-title:hover {
      :local(.doc-icon) {
        filter: drop-shadow(@text-primary-hover 0px 0);
      }
    }
  }
}
.doc-contain {

  display: flex;
  align-items: center;

  .doc-icon {
    overflow: hidden;
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  .doc-icon-content {

    filter: drop-shadow(currentColor 16px 0);
    transform: translateX(-16px);
    background-image: url('../../assets/svg/backlinks.svg');
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    border-right: 20px solid transparent;
    width: 100%;
    height: 100%;
    transition: filter 1s;
  }
}

.overview-contain {
  width: 100%;
  background: @background-color-2;
  // margin-top: 116px;
  padding-top: 60px;
  margin-top: 56px;

  .overview {
    display: flex;
    // width: @baseWidth;
    // padding: 0px 8px 81px;
    padding-top: 0;
    padding-bottom: 81px;
    margin: auto;
    align-items: center;
    height: 318px;
    gap: 58px;

    &-img {
      width: 428px;
      height: 242px;
      padding: 12px;
      box-shadow: 4px 6px 20px 0px @box-shadow-1;
      flex-shrink: 0;
      &>*{
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;

      &-title {
        font-size: 24px;
        font-weight: 700;

        &>span {
          padding: 0 5px;
        }

        img {
          width: 16px;
          height: 14.5px;
        }
      }

      &>span {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: @text-color;
      }
    }
  }
}


.features {
  width: 100%;
  background: @product-feature-bg-color;
  height: 700px;
  box-sizing: border-box;
  padding: 80px 0 140px;

  &-title {
    color: @text-color-black;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    // width: 1200px;
    margin: auto;
  }

  &-description {
    font-weight: 600;
    line-height: 24px;
    color: @text-color-secondary;
    // width: 1200px;
    margin: auto;
    margin-top: 8px;
  }
}

.benefits {
  width: 100%;
  background: @background-color-2;
  position: relative;
  // height: 626px;

  background-image: url('../../assets/svg/benefits-bg.svg');
  background-repeat: no-repeat;
  background-position: 100% 0;
  // &>div {
  //   width: 100%;
  //   background-image: url('../../assets/svg/benefits-bg.svg');
  //   background-repeat: no-repeat;
  //   background-position: 100%;
  // }




}


.slidein-contain {
  width: 100%;
  overflow: hidden;
}