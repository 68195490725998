@import '~@/index.less';
@img :'~@/assets/images';

.downDrawer {
  height: 446px;
  width: 100vw;
  // background: red;
  min-width: 1200px;
  // padding-bottom: 50px;
  // border-bottom: 1px solid #d9d9d9;

  :global {
    .ant-layout {
      height: 100%;
    }

    .ant-layout-sider {
      padding-left: 52px;
      max-width: 259px !important;
      width: 259px !important;
      flex: 0 0 259px !important;
      background: url('@{img}/img-bg-dropdown.png');
      background-size: cover;
      // background-color: #001529;
      .ant-dropdown-menu {
        padding-top: 8px;
        background-color: transparent;
        box-shadow: none;

        .ant-dropdown-menu-item {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: @text-color-white;
          padding-top: 24px;

          .nav-text {
            background: linear-gradient(to right, @text-primary-hover, @text-primary-hover) left bottom;
            background-size: 0 2px;
            background-repeat: no-repeat;
            padding-bottom: 10px;
            transition: background-size .5s;
          }
        }

        .ant-dropdown-menu-item-selected {
          color: @text-primary-hover  !important;
          background: initial !important;


          .nav-text {
            background-size: 100% 2px;
            font-weight: 700 !important;
          }

        }

        .ant-dropdown-menu-item:hover {
          background: initial !important;
        }
      }
    }

    .downDrawer-content-bg {
      background: @header-dropdown-bg;
    }

    .downDrawer-content {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      position: absolute;
      opacity: 0;
      z-index: 0;
      width: 100%;
      min-width: calc(100% - 298px);
      height: 445px;
      overflow-y: auto;
      transition: opacity 500ms ease 0s, visibility 500ms ease 0s;
      padding-inline: 20px;
      .downDrawer-content-right-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        width: 100%;
        padding: 20px 20px 0px;
        color: @text-color-white;

        img {
          margin-right: 5px;
        }

        img+img {
          margin-left: 5px;
        }

        hr {
          margin: 18px -36px -12px;
          border-color:@header-border-color;
          border-bottom: none;
        }

      }

      .downDrawer-content-item {
        width: 32%;
        font-style: normal;
        display: flex;
        padding-inline: 20px;
        flex-wrap: wrap;
        padding-top: 40px;
        overflow: hidden;
        height: 134px;

        img {
          margin-right: 8px;
        }

        .downDrawer-content-item-list {
          flex: 1;

          .downDrawer-content-item-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: @text-color-white;

            span {
              border-radius: 43px;
              display: inline-block;
              padding: 2.5px 7px;
              font-weight: 900;
              font-size: 10px;
              line-height: 12px;
              margin-left: 5px;
              text-transform: capitalize;
              transform: translateY(-2px);
            }

            .downDrawer-content-item-new {
              color: @text-color-white;
              background:@header-dropdown-tag-1;
            }

            .downDrawer-content-item-hot {
              color: @text-color-white;
              background: @header-dropdown-tag-2
            }

            .downDrawer-content-item-coming {
              color: @text-color-white;
              background: #6177EF
            }
          }

          .downDrawer-content-item-content {
            font-weight: 300;
            font-size: 14px;
            line-height: 22px;
            color: @text-color-secondary-dark;
            margin-top: 4px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }


      }

      .downDrawer-content-item:hover {
        .downDrawer-content-item-title {
          color: @text-primary-hover;
        }

        // img {
        //   // margin-right: 8px;
        //   transform: translateX(-8000px);
        //   filter: drop-shadow(@primary-color 8000px 0);
        //   background-blend-mode: multiply;
        // }
      }
    }

    .downDrawer-content-selectMenu {
      opacity: 1;
      z-index: 1;
    }
  }

}

.downSvg {
  height: 56px;
  padding-inline:24px;
  cursor: pointer;
  :global {
    .downTitle {
      overflow: hidden;

      img {
        padding-top: 10px;
        position: relative;
        transform: translateX(-800px);
        filter: drop-shadow(@background-color-2 800px 0);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }

      .space {
        color: @text-primary-hover
      }

      .img-filter {
        filter: drop-shadow(@text-primary-hover 800px 0)
      }
    }

  }
}