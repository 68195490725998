// 主题色
@primary-color: var(--primary-color,#E3222D);
//辅助主题色
@secondary-color:var(--secondary-color,#000000);//需要跟随辅助主题色切换的使用这个,不使用background-color-3

// 文本颜色
@text-primary-hover: var(--text-theme-hover,#FE101D);//文字hover颜色黑灰背景下
@text-primary-hover-light:var(--text-primary-hover-light,#E3222D);//文字hover颜色，白色背景下
@text-color-white:var(--text-color-white,#FFFFFF);//暗色背景下纯白字体
@text-color-black:var(--text-color-black,#000000);//白色背景下纯黑字体
@text-color:var(--text-color,#333333);//主文本颜色
@text-color-secondary: var(--text-color-secondary,#666666);//次文本色
@text-color-dark:var(--text-color-dark,#FFFFFF);//深色背景主文本色
@text-color-secondary-dark:var(--text-color-secondary-dark,rgba(255,255,255,0.85));//深色背景次文本色
@text-color-thirdary-dark:var(--text-color-thirdary-dark,rgba(255,255,255,0.65));//深色背景三级色

//背景颜色
@background-color-1:var(--background-color-1,#fff1f0);//背景颜色
@background-color-2:var(--background-color-2,#FFFFFF);
@background-color-3:var(--background-color-3,#000000);

//border
@border-color-white:var(--border-color-white,#FFFFFF);
@border-color-1:var(--border-color-1,#D9D9D9);
@border-color-2:var(--border-color-2, #BFBFBF);

//box-shadow
@box-shadow-1:rgba(0, 0, 0, 0.08);
@box-shadow-2:rgba(0, 0, 0, 0.12);

// component:全局按钮使用
@second-btn-color:var(--primary-color-light,#f04a4d);//二级按钮字体、border
@second-btn-bg-color:rgba(255,255,255,0.2);//二级按钮背景色
@second-btn-bg-color-hover:var(--background-color-2,#FFFFFF);//二级按钮hover背景
@animation-btn-color-1:var(--primary-color-dark,#d70306);//一级动画按钮animation-btn使用
@animation-btn-color-2:var(--primary-color,#E3222D);//一级动画按钮animation-btn使用
@animation-btn-color-3:#ff9400;//一级动画按钮animation-btn闪耀的黄色

// component:Feature
@feature-odd-bg-color:var(--background-color-2,#FFFFFF);//奇数背景色
@feature-odd-icon-bg-color:var(--background-color-1,#fff1f0);//奇数icon背景色
@feature-even-bg-color:var(--background-color3,#000000);//偶数背景色
@feature-even-icon-bg-color:rgba(255, 241, 240, 0.25);//偶数icon背景色
@feature-even-icon-bg-color-hover:rgba(255, 255, 255, 0.95);
@feature-bg-color-hover:var(--primary-color,#e3222d);//hover颜色

// component:Footer
@footer-background:var(--secondary-color,#000000);//底部默认背景色

//component:Header
@header-dropdown-bg:#2C3134;
@header-copy-success:var(--primary-color,#e3222d);//头像下拉框copy成功的打勾的颜色
@header-avatar-color-tag:#F97B05;//头像下拉框的邮箱后的tag的border和color
@header-avatar-text-hover:var(--text-primary-hover,#e3222d);//登出按钮的hover颜色
@header-nav-background:var(--secondary-color,#000000);//顶部导航默认背景色
@header-border-color:rgba(255,255,255,.25);//products下拉框border
@header-dropdown-tag-1:var(--primary-color,#e3222d);//products下拉框tag背景颜色
@header-dropdown-tag-2:#FF7518;//products下拉框tag背景颜色

//component: productCategory
@category-active-bg-color:var(--primary-color,#e3222d);
@category-bg-color:#C5C9D1;
@category-filter-shadow:rgba(0,0,0,0.25);

//component: tabs
@tabs-bg-color:rgba(255,255,255,0.8);
@tabs-bg-color-active:var(--primary-color,#e3222d);

//component: home/banner
@tabs-progress-bg-color:#666666;

//component: home/fixedTabs
@fixedTabs-bg-color:var(--secondary-color,#000000);

//page: pricing
@pricing-highlights-bg-color:rgba(197, 201, 209, 0.40);

//page: product
@product-feature-bg-color:rgba(197, 201, 209, 0.40);
@product-benefit-bg-color:rgb(237, 238, 241);
@product-isPay-bg-color:#17181D;
@product-free-bg-color:rgba(197, 201, 209, 0.40);
@product-table-cell-color:#8C8C8C;

//page: contact
@contact-presales-bg-color:rgba(197, 201, 209, 0.40);
@contact-presalse-card-color-before:#6a6a6a;
@contact-aftersalse-card-color:rgb(232, 233, 237);

//page: contactNow
@contactnow-text-color:var(--text-color, #262626);
@contactnow-border-color:var(--gray-gray-13-black, #000);
@contactnow-input-border-color:var(--border-active, #c1c6ca);
@contactnow-button-disabled-color:#bfbfbf;

//page: documentCenter
@document-banner-search-bg:rgba(244, 244, 244, 0.60);
:root {
  --primary-color-light:#f04a4d;
  --primary-color-dark:#d70306;
  --primary-color: #e3222d;
  --secondary-color:#000000;

  // 文本颜色;
  --text-color: #333333;
  --text-color-secondary: #666666;
  --text-color-dark: #FFFFFF;
  --text-color-secondary-dark: rgba(255,255,255,0.85);
  --text-color-thirdary-dark: rgba(255,255,255,0.65);
  --text-primary-hover:#FE101D;
  --text-primary-hover-light:#E3222D;
  --text-color-white:#FFFFFF;
  --text-color-black:#000000;
  
  // 背景颜色
  --background-color-1:#fff1f0;//主要的背景色
  --background-color-2:#FFFFFF;//纯白背景色
  --background-color-3:#000000;//纯黑背景色

  //border颜色
  --border-color-white:#FFFFFF;
  --border-color-1:#D9D9D9;
}