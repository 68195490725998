@import '~@/index.less';

.layou {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;

  :global {
    .layou-content {
      min-width: 1200px;
      width: 100%;
      padding: 0;

      .ant-layout-header {
        height: 60px;
        padding-inline: 0;
        background-color: #000;
      }
    }
  }
}
.header{
  position: relative;
}