.slidein-item {
  // opacity: 0;
  // transform: translateY(100px);
  // transition: transform .4s ease-in-out;
  // background: orange;
  // padding-top: 100px;
  opacity: 0;
}

.slidein-end {
  transform: translate(0);
  opacity: 1;
}

.slidein-from-bottom {
  animation: slideInFromBottom .4s ease-in-out;
}

.slidein-from-top {
  animation: slideInFromTop .4s ease-in-out;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100px);
    opacity: 0;
  }

  to {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translate(0);
    opacity: 1;
  }
}