@import '~@/index.less';

.avatarDropdown {
  width: 111px;
  height: 60px;
  position: relative;

  :global {
    .ant-divider {
      margin: 20px 0px;
    }

    .avatarDropdown-userInfo {
      position: absolute;
      right: 0;
      top: 60px;
      z-index: 1;
      width: 300px;
      background: @header-dropdown-bg;
      color: @text-color-white;
      cursor: initial;
      padding: 0 20px;
      max-height: 0;
      overflow: hidden;
      transition: all 0.15s ease;

      .avatarDropdown-userInfo-card {
        text-align: left;

        .accountId {
          display: flex;
          align-items: center;

          div+div {
            flex: 1;
            min-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          :global {
            .ant-typography {
              margin-bottom: 0 !important;
              margin-top: 0 !important;
              display: flex;
              height: 22px;
              width: 100%;
              color: inherit;
              justify-content: space-between;
              opacity: revert;

            }

            //.ant-typography-copy{
            //  text-align: right;
            //}
            .ant-typography-copy-success {
              color: @primary-color;
            }
          }
        }
      }

      .ant-divider-horizontal {
        color: @text-color-white;
        border-block-start-color: @border-color-white;
        opacity: .3;
        margin: 12px 0px;
      }

      .avatarDropdown-userInfo-card1 {
        display: flex;
        padding-top: 20px;

        .ant-avatar {
          min-width: 36px;
        }

        .avatarDropdown-userInfo-card1-text1 {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;

          .mail {
            display: flex;
            flex-direction: row-reverse;
            max-width: 260px;

            div+div {
              flex: 1;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .avatarDropdown-userInfo-card1-text2 {
          font-weight: 600;
          font-size: 10px;
          line-height: 18px;
          margin: 6px 0;
          transform: scale(0.84);
          -webkit-transform: scale(0.84);
          margin-left: -8%;

          span {
            color:@header-avatar-color-tag;
            border-radius: 4px;
            padding: 1px 10px;
            border: 1px solid @header-avatar-color-tag;

          }
        }


      }

      .avatarDropdown-userInfo-card2>div {
        display: flex;
      }

      .avatarDropdown-userInfo-card2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        img {
          width: 14px;
          height: 22px;
          margin-left: auto;
        }

        div div {
          min-width: 87px;
          opacity: .65;
        }



        div+div {
          margin-top: 8px;
        }
      }

      .avatarDropdown-userInfo-card3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin: 18px 0 20px;
      }

      .avatarDropdown-userInfo-card3:hover {
        color: @header-avatar-text-hover;
      }
    }

    .avatarDropdown-userInfo:hover {
      display: block;
    }


  }


}

.avatarDropdown:hover {
  :global {
    .avatarDropdown-userInfo {
      max-height: 600px;
    }
  }
}

:global {
  .avatarDropdown-mail-tooltip {
    z-index: 9999;
    --antd-arrow-background-color: @background-color-2;

    .ant-tooltip-inner {
      color: @text-color-black;
      background-color: @background-color-2;
    }
  }
}

.hasAvatarDropdown {
  width: initial;
  padding-inline: 10px 32px;
  display: flex;
  align-items: center;
}