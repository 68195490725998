@import '@/index.less';
@baseWidth: 1200px;

.pay {
  width: 100%;
  height: 414px;
  background-image: url('../../../assets/images/img-isPay.png');
  background-blend-mode: soft-light;
  background-color: @product-isPay-bg-color;
  background-repeat: no-repeat;
  background-size: cover;
  color: @background-color-2;

  &-content {
    width: @baseWidth;
    margin: auto;

    &-title {
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 68px;

      &>span {
        padding: 0 5px;
        font-family: Open Sans;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }

      img {
        width: 16px;
        height: 14.5px;
      }
    }

    &-description {
      margin-top: 16px;
      text-align: center;
      color: @text-color-secondary-dark;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &-method {
      display: flex;
      justify-content: space-between;
      padding: 0 70px;
      margin-top: 74px;

      &-item {
        width: 481px;
        display: flex;
        gap: 30px;  
        align-items: flex-start;
        &>img {
          width: 60px;
          // height: 68.5px;
        }

        &>div {

          &>span {
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
          }

          &>div {
            margin-top: 10px;
            color: @text-color-thirdary-dark;
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.table {
  width: 100%;
  margin: auto;
  padding: 20px 0 62px;
  background-color: @background-color-2;

  .region-tab {
    // max-width: 1440px;
    // min-width: 1200px;
    margin: auto;
    overflow: hidden;
    padding-bottom: 20px;

    :global {

      .ant-tabs-nav {
        margin-bottom: 32px;

        &::before {
          border-color: @border-color-1 !important;
        }

      }

      .ant-tabs-tabpane-hidden {
        display: block !important;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        // left: 100%;

      }

      .ant-tabs-tab {
        font-size: 18px;
      }

      // .ant-tabs-tab-active{
      //   color:@primary-color;
      // }
    }
  }

  .type-tab {
    box-sizing: border-box;
    width: 100%;
    padding: 0 40px;
    margin: auto;

    :global {
      .ant-tabs-nav {
        margin-bottom: 0;

        &::before {
          border-color: @border-color-1 !important;
        }
      }

      .ant-tabs-tabpane-hidden {
        display: block !important;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        // left: 100%;

      }

      .ant-tabs-tab {
        font-size: 16px;
        border-radius: 0px !important;
        background-color: transparent;
        border: 1px solid @border-color-1;
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {

          color: @text-color-white;
        }

        background-color: @primary-color;
      }
    }
  }

  &-content {
    padding: 20px 20px 40px 20px;
    box-shadow: 0px 3px 12px 0px @box-shadow-2;
    overflow: hidden;
  }
}

.free {
  background-color: @product-free-bg-color;

  height: 644px;
  width: 100%;

  &-content {
    width: @baseWidth;
    margin: auto;
    background-size: cover;
    padding-top: 80px;
    position: relative;
    height: 100%;

    &-title {
      color: @text-color-black;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    &-description {
      color: @text-color-secondary;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      // text-transform: capitalize;
      margin-top: 8px;
    }

    &>img {
      width: 1200px;
      height: 450px;
      position: absolute;
      bottom: 0;

    }

    &-benefit {
      color: @text-color-black;
      font-family: Open Sans;
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      position: absolute;
      &:nth-child(4){
        // background: white;
        left: 39px;
        bottom:141px
      }
      &:nth-child(5){
        left: 481px;
        top: 238px;
      }
      &:nth-child(6){
        right: 26px;
        bottom: 129px;
      }
    }
  }
}

.slidein-contain {
  width: 100%;
  overflow: hidden;
}

