@import '~@/index.less';

.layoutFooter {
  min-height: 304px;
  width: 100%;
  background-color: @footer-background;
  color: @text-color-white;
  padding-top: 36px;

  :global {
    .layoutFooter-content {
      display: flex;
      margin: auto;

      .layoutFooter-content-left {
        display: flex;
        width: 80%;
        gap: 48px;
        justify-content: space-around;

        .layoutFooter-content-item {
          // width: 20%;


          .layoutFooter-content-item-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
          }

          .layoutFooter-content-item-content {
            font-weight: 600;
            font-size: 14px;
            line-height: 30px;
            color: @text-color-thirdary-dark;
            cursor: pointer;
          }

          .layoutFooter-content-item-content:hover {
            color: @text-primary-hover;
          }
        }
      }

      .layoutFooter-content-right {
        padding-left: 50px;
        width: 20%;

        .layoutFooter-content-right-content {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: @text-color-thirdary-dark;
          margin: 16px 0 24px;
        }

        .layoutFooter-content-right-logoList {
          margin-left: -8px;
          margin-top: -10px;

          img {
            margin-top: 10px;
            margin-left: 8px;
          }
        }
      }
    }

    .layoutFooter-divider {

      margin: 36px auto 30px;

      &>div {
        background-color: @background-color-2;
        opacity: 0.4;
        min-width: initial !important;
      }
    }

    .layoutFooter-footer {
      display: flex;
      justify-content: space-between;
      margin: auto;
      padding-bottom: 59px;
      font-size: 12px;
      .layoutFooter-left {
        width: 50%;
      }

      .layoutFooter-right {
        max-width: calc(50% - 100px);
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 8px;
        img{
          height: 42px;
        }
      }
      .layoutFooter-link{
        display: flex;
       flex-wrap: wrap;
       row-gap: 8px;
       column-gap: 32px;
        margin-bottom: 8px;
        line-height: 18px;
        &>a{
          color:@text-color-white;
          &:hover{
            color:@text-primary-hover;
          }
        }
      }
      .layoutFooter-text{
        color:@text-color-thirdary-dark;
        
        &>div{
          margin-bottom: 8px;
        }
      }
    }
  }

}

@media screen and (max-width: 1439px) {
  .layoutFooter {
    min-width: 1200px;
  }
}