@import '~@/index.less';
@img :'~@/assets/images';


.header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: @text-color-white;
  padding-inline: 0;
  line-height: 60px;
  height: 60px;
  background-color: @header-nav-background;
  position: fixed;
  // position: absolute;
  width: 100%;
  z-index: 9999;

  :global {
    .header-right {
      display: flex;
      margin-left: auto;
    }

    .header-left-title {
      padding-inline: 24px;
      cursor: pointer;
      color: #fff;

      &:hover {
        color: @text-primary-hover;
      }
    }

    .header-left-title:hover {
      color: @text-primary-hover;

      img {
        filter: drop-shadow(@text-primary-hover 800px 0);
      }
    }

    .header-logo {
      display: flex;
      align-items: center;

      &>img {
        height: 30px;
        margin-left: 44px;
        margin-right: 30px;
        object-fit: cover;
      }
    }

    .header-right-title {
      padding: 0 30px 0 4px;

      img {
        margin-top: 1px;
        display: block;
      }
    }

    .header-right-logIn {
      width: 111px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 1439px) {
  .header {
    min-width: 1200px;
    position: initial;
  }
}