@import '@/index.less';
@baseWidth: 1200px;

.price-highlights {
  

  &-container {
    // width: @baseWidth;
    margin: auto
  }

  &-contain {
    margin-top: 66px;
    display: flex;
    align-items: center;
    height: 350px;
  }

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    width: 25%;
    transition: all 0.5s;
    background-color: @feature-bg-color-hover;
    background-image: url('../../assets/svg/bg-price-hover.svg');
    background-blend-mode: multiply;
    background-size: cover;
    &:nth-child(2n) >div {
      background-color: @secondary-color;

      .price-highlights-content-img {
        background-color: @feature-even-icon-bg-color;
      }

      .price-highlights-content-item {
        &>span {
          color: @text-color-secondary-dark !important;
        }

        &>div {
          color: @text-color-thirdary-dark !important;
        }
      }
    }

    &:nth-child(2n-1)> div {
      background-color:@feature-odd-bg-color;

      .price-highlights-content-img {
        background-color: @feature-odd-icon-bg-color;
      }
    }
    &:hover {
      height: 120% !important;
      &:nth-child(2n) >div{
        .price-highlights-content-img{
          background-color: @feature-even-icon-bg-color-hover;
        }
      }
      &>div{
        background: transparent;
      }
      .price-highlights-content-img{
        margin-bottom: 38px;
      }
      .price-highlights-content-item {
        &>span {
          color: @text-color-dark !important;
        }

        &>div {
          color: @text-color-secondary-dark !important;
        }
      }
    }
    &>div {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 47px 24px 43px;
      width: 100%;
      height: 100%;
      transition: all 0.5s;
      
    }

    &-img {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 28px;
      transition: all 0.5s;
      &>img {
        width: 50px;
        height: 50px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &>span {
        text-align: center;
        color: @text-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      &>div {
        font-weight: 400;
        line-height: 22px;
        color:@text-color-secondary;
        text-align: center;
      }
    }

  }
}