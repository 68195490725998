@import '@/index.less';
@baseWidth: 1200px;
.banner {
  width: 100%;
  height: 420px;
  position: relative;


  &>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &-video {
    // margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    // width: fit-content;
    position: relative;
    &>video {
      width: 100%;
      max-width: 1920px;
      height: 100%;
      object-fit: cover;
    }
    
    &-left-mask{
      position: absolute;
      top: 0;
      left: calc((100% - 1920px) / 2);
      height: 100%;
      width: 300px;
      
    }
    &-right-mask{
      position: absolute;
      right: calc((100% - 1920px) / 2);
      top: 0;
      height: 100%;
      width: 300px;
    }
  }

  &-content {
    position: absolute;
    // left: 50%;
    // transform: translate(-50%);
    top: 0px;
    // width: @baseWidth;
    width: 100%;
    height: 100%;
    
  }
}