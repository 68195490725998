@import '~@/index.less';

.second-btn {
  
  background-color: @second-btn-bg-color;
  border-color: @second-btn-color;
  color: @second-btn-color;
  transition: all 0.5s;
  &-hover-bg{
    &:hover{
      background-color: @background-color-2 !important;
    }
    
  }
  
  &:hover {
    opacity: 1;
    border-color: @second-btn-color !important;
    color: @second-btn-color !important;
  }

  &:active{
    border-color: @second-btn-color !important;
    color: @second-btn-color !important;
  }
}
