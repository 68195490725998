@import '~@/index.less';

@font-face {
  font-family: Open Sans;
  src: 
  local('Arial'),
  url('./assets/fonts/Open Sans.ttf');
}

@font-face {
  font-family: Open Sans;
  src: 
  local('Arial Bold'),
  url('./assets/fonts/Open Sans Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Open Sans;
  src: 
  local('Arial Bold'),
  url('./assets/fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

:global(.ant-layout),
div,
span,
p body {
  font-family: 'Open Sans';
  // font-family: Arial;
}

:global {
  .position-relative {
    position: relative;
  }

  .ant-btn {
    border-radius: 2px;
  }

  .content-centent {
    width: 1440px;
    margin: 0 auto;
    padding-inline: 120px;
  }

  .bg-theme {
    background-color: @primary-color ;
  }

  .flex {
    display: flex;
  }

  .flex-center {
    align-items: center;
    justify-content: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .animation-btn {
    background: @primary-color;
  }
  .animation-btn-always :local{
    background: linear-gradient(-45deg, @animation-btn-color-1 50%, @animation-btn-color-3 60%, @animation-btn-color-2 70%);
    background-size: 600% 100%;
    -webkit-animation: shine 20s linear infinite;
    animation: shine 20s linear infinite;
  }
  .animation-btn:hover :local {
    background: linear-gradient(-45deg, @animation-btn-color-1 50%, @animation-btn-color-3 60%, @animation-btn-color-2 70%);
    background-size: 600% 100%;
    -webkit-animation: shine 20s linear infinite;
    animation: shine 20s linear infinite;
  }

  @media screen and (max-width: 1439px) {
    .content-centent {
      width: 100%;
      min-width: 1200px;
      padding-inline: 80px;
    }
  }

  

  @keyframes shine {
    0% {
      background-position-x: 400%;
    }

    50% {
      background-position-x: 0%;
    }

    100% {
      background-position-x: -400%;
    }
  }
}


// 滚动条
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: none;
  background-clip: padding-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}

::-webkit-scrollbar-thumb {
  width: 43px;
  border-radius: 3px;
  background-color: #ccc;
  -webkit-box-shadow: inset 1px 1px 0 #ccc;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 8px #fff;
  background-color: transparent;
}